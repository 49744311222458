
import {
    PropType,
    computed,
    defineComponent,
    onMounted,
    onUnmounted,
    ref,
    watch,
    onBeforeMount,
    getCurrentInstance,
    watchEffect,
    onDeactivated,
} from 'vue'
import SettingsListItem from './SettingsLiItem.vue'
import BaseInputOutlined from '../../inputs/BaseInputOutlined.vue'
import CustomActivatorSelect from '../../inputs/CustomActivatorSelect.vue'
import * as _ from 'lodash'
import { Connector } from '../../../types/main'
import { useProject } from '@root/src/hooks/useProject'
import { sendMassMail } from '../../../api/mailing'
import api from '../../../api/api'
import SpinnerLoader from '../../SpinnerLoader.vue'
import TimeZonePicker from '../../ui/TimeZonePicker.vue'
import SmallHint from '../../SmallHint.vue'
import websocket from '../../../websockets'
import ValidationInput from '../../inputs/ValidationInput.vue'
import phoneValidator, { isValidPhone } from '../../../helpers/validators/phoneValidator'
import { CONNECTOR_TYPES } from '@root/src/vars/general'
import PhoneInputGroup from '../../ui/PhoneInputGroup.vue'

export default defineComponent({
    props: {
        value: {
            type: Object,
            required: true,
        },
        saveButtonLoading: {
            type: Boolean,
            required: true,
        },
        saveButtonText: {
            type: String,
            default: 'Сохранить',
        },
        appCreated: {
            type: Boolean,
            default: false,
        },
        connector: {
            type: Object as PropType<Connector>,
            required: true,
        },
        yClientsConnector: {
            type: Object as PropType<Connector>,
            required: true,
        },
        ycMpConnectionStatusText: {
            type: String,
        },
        mode: {
            type: String,
            default: 'create',
        },
        tgStatusAlive: {
            type: String,
            default: 'no_auth',
        },
    },
    components: {
        SettingsListItem,
        BaseInputOutlined,
        CustomActivatorSelect,
        SpinnerLoader,
        TimeZonePicker,
        SmallHint,
        ValidationInput,
        PhoneInputGroup,
    },
    setup(props, { emit }) {
        const unAuthInProcess = ref(false)
        const root = getCurrentInstance().proxy
        const { project } = useProject()
        const yClientsConnector = computed<Connector>(() =>
            root.$store.getters
                .projectById(root.$router.currentRoute.params.id)
                .connectors.find((el: Connector) => el.connectorType === CONNECTOR_TYPES.yClients)
        )
        const testModeHintText = 'В тестовом режиме все сервисные сообщения будут отправляться на номер, который необходимо указать в поле ниже'
        const visitGroupHintText = `Если включить это функцию, то клиентам, которые записаны на несколько услуг, будет отправляться только одно сообщение на группу услуг, а не несколько на каждую услугу.
        \nДля того чтобы услуги объединились, между ними (временем окончания первой услуги и временем начала второй) должно быть не более 30 минут.`
        const onlyForMailingHintText = `Использовать бот для рассылок только по сегментациям. Сервисные сообщения отправляться не будут`
        const updateSetting = (key: string, value: any) => {
            const tempObj = Object.assign({}, props.value)
            _.set(tempObj, key, value)
            emit('input', Object.assign({}, props.value ?? {}, tempObj))
        }

        const wasTimezoneAdded = ref(false)
        const fetchQrInterval = ref<any>()
        const qr = ref({ loading: true, data: {} as any })
        const botStatus = ref('false')
        const testMessage = ref({ phone: '', loading: false })
        const selectedBotProvider = ref(localStorage.getItem('selectedBotProvider') || 'basis-api')
        const isTgAlive = ref('no_auth')

        const connectedBot = computed(() => {
            if (props.value.channel?.['wApi-telegram']?.credentials || isTgAlive.value === 'auth') {
                return 'telegram 1'
            } else return 'whatsapp 1'
        })

        const tgSendCode = ref({
            phone: '',
            isSendCode: false,
            countdown: 60,
            condition: 'noAuth',
            code: '',
        })
        const requiredFields = computed(() => {
            const fields = [
                { name: 'claim.claimPhone', isPhone: true, isPhoneArray: true },
                { name: 'cancel.cancelPhone', isPhone: true, isPhoneArray: true },
                { name: 'redirect.needToRedirect', isPhone: false },
                { name: 'salonName', isPhone: false },
                { name: 'salonAddress', isPhone: false },
                { name: 'salonPhone', isPhone: true },
                { name: 'groupByVisit', isPhone: false },
                { name: 'phoneForNotification', isPhone: true },
                { name: 'timezone', isPhone: false },
            ]
            if (_.get(props.value, 'redirect.needToRedirect')) {
                fields.push({ name: 'redirect.redirectPhone', isPhone: true })
            }
            return fields
        })
        const isValid = computed(() => {
            const fieldIsValid = (fieldName: string) =>
                !_.isNil(_.get(props.value, fieldName)) && _.get(props.value, fieldName) !== ''
            return requiredFields.value.every(field => {
                if (field.isPhone) {
                    if (!field.isPhoneArray) {
                        return isValidPhone(_.get(props.value, field.name))
                    } else {
                        const phoneArr = _.get(props.value, field.name)
                        return phoneArr.every(el => isValidPhone(el))
                    }
                } else {
                    return fieldIsValid(field.name)
                }
            })
        })
        const botMods = [
            {
                id: true,
                name: 'Тестовый режим',
            },
            {
                id: false,
                name: 'Боевой режим',
            },
        ]
        const groupByVisitList = [
            {
                id: true,
                name: 'Да',
            },
            {
                id: false,
                name: 'Нет',
            },
        ]
        const smsBySpecialCharacterList = [
            {
                id: true,
                name: 'Да',
            },
            {
                id: false,
                name: 'Нет',
            },
        ]
        const allBotProviderList = [
            {
                id: 'basis-api',
                name: 'WhatsApp 1',
            },
            {
                id: 'wApi-telegram',
                name: 'Telegram 1',
            },
        ]

        const setTimezone = evt => {
            emit('inputTimezone', evt)
            wasTimezoneAdded.value = true
            updateSetting('timezone', evt)
        }
        const sendTestMesage = async () => {
            try {
                testMessage.value.loading = true
                const { data: sendMessageData, error: senMessageError } = await sendMassMail({
                    projectId: props.connector.projectId,
                    connectorId: props.connector.connectorId,
                    service: 'whatsappBasis',
                    messages: [
                        {
                            from: props.value.phone.replace(/[^0-9]/g, ''),
                            to: testMessage.value.phone.replace(/[^0-9]/g, ''),
                            text: 'Тестовое сообщение',
                            communicationId: null,
                        },
                    ],
                })
                if (senMessageError) {
                    root.$store.dispatch('callNotify', 'Ошибка при отправке сообщения (1)')
                    testMessage.value.loading = false
                    return
                }
                testMessage.value.loading = false
            } catch (err) {
                console.error(err)
                root.$store.dispatch('callNotify', 'Ошибка при отправке сообщения (2)')
                testMessage.value.loading = false
            }
        }
        const unAuthInstance = async () => {
            try {
                unAuthInProcess.value = true
                testMessage.value.loading = true
                await api.unAuthInstance({ connectorId: props.connector.connectorId })
                qr.value.data = { type: 'qrCode' }
                testMessage.value.loading = false
            } catch (e) {
                testMessage.value.loading = false
                root.$store.dispatch('callNotify', 'Ошибка при удалении авторизации')
            }
        }
        const checkAndHandleWaStatus = async () => {
            qr.value.loading = true
            if (
                props.value.channel?.['wApi-telegram']?.credentials &&
                selectedBotProvider.value === 'wApi-telegram'
            ) {
                if (!props.tgStatusAlive && props.tgStatusAlive !== 'auth') {
                    qr.value.data = { type: 'qrCode' }
                    return
                }
                qr.value.data = { type: 'alreadyLogged' }
                qr.value.loading = false
                unAuthInProcess.value = false
                return
            }

            if (props.value.channel?.['wApi-telegram']?.credentials) {
                selectedBotProvider.value = 'wApi-telegram'
            }

            if (props.tgStatusAlive === 'auth') return
            if (selectedBotProvider.value === 'basis-api') {
                qr.value.data = await api.networkFixer
                    .getAppQr({ connectorId: props.connector.connectorId })
                    .then(res => res.data ?? {})
            }
            if (qr.value.data?.type === 'qrCode') {
                const qrCodeImage = root.$refs.qrCode as any
                if (qrCodeImage) {
                    qrCodeImage.setAttribute('src', 'data:image/jpg;base64,' + qr.value.data?.message)
                    unAuthInProcess.value = false
                }
            }
            if (qr.value.data.type === 'alreadyLogged' && !props.value.phone) {
                if (!unAuthInProcess.value) {
                    const [app, error] = await api.networkFixer
                        .getApp({ connectorId: props.connector.connectorId })
                        .then(res => [res.data!.data, res.error])
                    emit('input', Object.assign({}, props.value, { phone: app.phone }))
                    if (!error && project.value.isOnBoardingActive) {
                        project.value.isOnBoardingActive = false
                        await root.$store.dispatch('project/updateProjectSettings', {
                            projectId: project.value.id,
                            settings: project.value,
                        })
                    }
                }
            }

            qr.value.loading = false
        }

        const setFetchQrInterval = async () => {
            if (props.value.disabled) {
                return
            }
            if (props.appCreated) {
                checkAndHandleWaStatus()
            }
            fetchQrInterval.value = setInterval(async () => {
                if (props.appCreated) {
                    checkAndHandleWaStatus()
                }
            }, 5 * 1000)
        }

        const isBotConnected = computed(() => {
            return (
                qr.value.data.type === 'alreadyLogged' ||
                (!props.value.disabled && !qr.value.data.type && props.appCreated) ||
                (!props.value.disabled && qr.value.data.type === 'qrCode')
            )
        })

        const sendCode = async () => {
            try {
                qr.value.data = { type: 'qrCode' }
                const instance = await api.networkFixer.createInstanceTg({
                    connectorId: props.connector.connectorId,
                })
                if (instance.error) {
                    root.$store.dispatch('callNotify', 'Ошибка при отправке кода (1)')
                    return
                }
                const data = await api.networkFixer.sendCodeTg({
                    connectorId: props.connector.connectorId,
                    phone: tgSendCode.value.phone,
                })
                if (data.error) {
                    root.$store.dispatch('callNotify', 'Ошибка при отправке кода (2)')
                    return
                }
                unAuthInProcess.value = false
                tgSendCode.value.code = ''
                tgSendCode.value.isSendCode = true
            } catch (e) {
                console.error(e)
                root.$store.dispatch('callNotify', 'Ошибка при отправке кода (3)')
            }
        }

        const inputCode = async () => {
            try {
                const res = await api.networkFixer.inputCodeTg({
                    connectorId: props.connector.connectorId,
                    code: tgSendCode.value.code,
                })
                if (!res?.error) {
                    qr.value.data = { type: 'alreadyLogged' }
                    emit('input', Object.assign({}, props.value, { phone: tgSendCode.value.phone }))
                    isTgAlive.value = 'auth'
                }
            } catch (e) {
                console.error(e)
                root.$store.dispatch('callNotify', 'Ошибка при отправке кода (4)')
            }
        }

        watch(
            () => tgSendCode.value.isSendCode,
            newValue => {
                tgSendCode.value.countdown = 60
                let timer = setInterval(() => {
                    tgSendCode.value.countdown--
                    if (tgSendCode.value.countdown <= 0) {
                        clearInterval(timer)
                        tgSendCode.value.isSendCode = false
                    }
                }, 1000)
            }
        )

        watch(
            () => props.appCreated,
            v => {
                if (v && !fetchQrInterval.value) {
                    setFetchQrInterval()
                }
            },
            { immediate: true }
        )
        watch(
            () => selectedBotProvider.value,
            () => {
                localStorage.setItem('selectedBotProvider', selectedBotProvider.value)
            }
        )
        watch(
            () => props.tgStatusAlive,
            val => {
                if (val === 'auth') {
                    selectedBotProvider.value = 'wApi-telegram'
                }
            }
        )

        onUnmounted(() => {
            updateSetting('timezone', root.$store.getters.timezone(project.value.id))
            clearInterval(fetchQrInterval.value)
            websocket.socket.off('WA_BASIS_PHONE_UPDATED')
        })
        onBeforeMount(() => {
            if (_.isString(props.value.claim.claimPhone)) {
                updateSetting('claim.claimPhone', [props.value.claim.claimPhone])
            }
            if (_.isUndefined(props.value.cancel?.cancelPhone)) {
                updateSetting('cancel.cancelPhone', [])
            }
        })
        onMounted(() => {
            websocket.socket.on('WA_BASIS_PHONE_UPDATED', async payload => {
                emit('input', Object.assign({}, props.value, { phone: payload.phone }))
            })
        })

        return {
            setTimezone,
            wasTimezoneAdded,
            isBotConnected,
            botStatus,
            updateSetting,
            botMods,
            isValid,
            emit,
            testMessage,
            sendTestMesage,
            get: _.get,
            qr,
            groupByVisitList,
            botProvider: allBotProviderList,
            visitGroupHintText,
            testModeHintText,
            onlyForMailingHintText,
            smsBySpecialCharacterList,
            selectedBotProvider,
            tgSendCode,
            sendCode,
            inputCode,
            unAuthInstance,
            unAuthInProcess,
            connectedBot,
            phoneValidator,
            isValidPhone,
            yClientsConnector,
            isTgAlive,
        }
    },
})
